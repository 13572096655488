/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RiArrowRightSLine } from "react-icons/ri"
import {
  RiFacebookBoxFill,
  RiTwitterFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiRssFill,
  RiGithubFill,
  RiTelegramFill,
  RiPinterestFill,
  RiSnapchatFill,
  RiSkypeFill,
  RiDribbbleFill,
  RiMediumFill,
  RiBehanceFill,
  RiMailFill,
} from "react-icons/ri"
import { FaTiktok, FaWordpress, FaVk } from "react-icons/fa"

import LayoutHome from "../components/layouthome"
// import BlogListHome from "../components/blog-list-home"
import Seo from "../components/seo"
import Icons from "../util/socialmedia.json"
import ArtTypeCard from "../components/art-type-cards"

import MuralImg from "../../static/assets/Earth-III(1)_thumbnail.jpg"
import CeramicsImg from "../../static/assets/Image-for-Ceramics-min-903x1024.jpg"
import SculptureImg from "../../static/assets/jiyas_cushion-950x1024.jpg"
import InterviewImg from "../../static/assets/La-Source-Dec-2020.jpg"
import PCImg from "../../static/assets/TriCity-Nov-2020.jpg"
import { Carousel } from "react-responsive-carousel"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, posts } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  const sIcons = Icons.socialIcons.map((icons, index) => {
    return (
      <div
        key={"social icons" + index}
        style={{
          verticalAlign: "top",
        }}
      >
        {icons.icon === "facebook" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Facebook"
            rel="noopener noreferrer"
          >
            <RiFacebookBoxFill alt="Facebook icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "twitter" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Twitter"
            rel="noopener noreferrer"
          >
            <RiTwitterFill alt="Twitter icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "linkedin" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Linkedin"
            rel="noopener noreferrer"
          >
            <RiLinkedinBoxFill alt="Linkedin icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "youtube" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Youtube"
            rel="noopener noreferrer"
          >
            <RiYoutubeFill alt="Youtube icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "instagram" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Instagram"
            rel="noopener noreferrer"
          >
            <RiInstagramFill alt="Instagram icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "rss" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to RSS"
            rel="noopener noreferrer"
          >
            <RiRssFill alt="RSS icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "github" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Github"
            rel="noopener noreferrer"
          >
            <RiGithubFill alt="Github icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "telegram" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Telegram"
            rel="noopener noreferrer"
          >
            <RiTelegramFill alt="Telegram icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "pinterest" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Pinterest"
            rel="noopener noreferrer"
          >
            <RiPinterestFill alt="Pinterest icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "snapchat" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Snapchat"
            rel="noopener noreferrer"
          >
            <RiSnapchatFill alt="Snapchat icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "skype" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Skype"
            rel="noopener noreferrer"
          >
            <RiSkypeFill alt="Skype icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "wordpress" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Wordpress"
            rel="noopener noreferrer"
          >
            <FaWordpress alt="Wordpress icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "tiktok" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Wordpress"
            rel="noopener noreferrer"
          >
            <FaTiktok alt="tiktok icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "dribbble" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Dribbble"
            rel="noopener noreferrer"
          >
            <RiDribbbleFill alt="Dribbble icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "medium" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Medium"
            rel="noopener noreferrer"
          >
            <RiMediumFill alt="Medium icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "behance" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to Behance"
            rel="noopener noreferrer"
          >
            <RiBehanceFill alt="Behance icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "vk" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to vk"
            rel="noopener noreferrer"
          >
            <FaVk alt="vk icon" />
          </a>
        ) : (
          ""
        )}
        {icons.icon === "email" ? (
          <a
            href={icons.url}
            target="_blank"
            aria-label="link to email"
            rel="noopener noreferrer"
          >
            <RiMailFill alt="email icon" />
          </a>
        ) : (
          ""
        )}
      </div>
    )
  })
  return (
    <LayoutHome>
      <Seo />
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "16px",
        }}
      > */}
      <h1
        className="About"
        id="about"
        style={{
          paddingTop: "30px",
          paddingLeft: "30px",
        }}
      >
        About
      </h1>
      {/* </div> */}
      <div className=" grids col-1 sm-2" style={{ paddingTop: 30 }}>
        <div>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </div>

        <div id="aboutme">
          <div
            className="description"
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <br></br>
          <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <Link
              to={frontmatter.cta.ctaLink}
              className="button"
              sx={{
                variant: "variants.button",
              }}
            >
              {frontmatter.cta.ctaText}
              <span>
                <RiArrowRightSLine />
              </span>
            </Link>

            <div
              className="social-icons"
              sx={{
                variant: "variants.socialIcons",
              }}
              style={{
                "vertical-align": "top",
              }}
              id="contact"
            >
              {sIcons}
            </div>
          </div>
        </div>
      </div>
      {/* <BlogListHome data={posts} /> */}
      <h1
        className="portfolio"
        id="portfolio"
        style={{
          paddingTop: "30px",
          paddingLeft: "30px",
        }}
      >
        Portfolio
      </h1>
      <div>
        <div
          style={{ paddingTop: 30, paddingBottom: 30 }}
          className="grids col-1 sm-3 lg-3"
        >
          <ArtTypeCard
            key={1}
            data={{
              title: "Sculptures",
              img: SculptureImg,
              slug: "/sculptures",
            }}
          />
          <ArtTypeCard
            key={2}
            data={{ title: "Ceramics", img: CeramicsImg, slug: "/ceramics" }}
          />
          <ArtTypeCard
            key={0}
            data={{ title: "Murals", img: MuralImg, slug: "/murals" }}
          />
        </div>
      </div>
    </LayoutHome>
  )
}

export default HomePage
