/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { useState } from "react"
// import { GatsbyImage } from "gatsby-plugin-image"

const ArtTypeCard = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)
  return (
    <article
      className="post-card bg"
      sx={{
        bg: "cardBg",
      }}
      style={{ justifyContent: "center" }}
    >
      {data.img ? (
        <Link
          to={data.slug}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <img
            src={data.img}
            className="home-img"
            style={{ maxHeight: "350px", maxWidth: "330px", paddingTop: "0px" }}
            alt={data.title}
          ></img>
        </Link>
      ) : (
        ""
      )}
      <Link
        to={data.slug}
        sx={{
          variant: "links.postLink",
        }}
      >
        <div className="post-content overlay">
          {/* <h2 className="title">{data.title}</h2> */}
          <p
            className="meta"
            sx={{
              color: "muted",
            }}
          ></p>
        </div>
        {width < 5000 ? (
          <div className="post-content">
            <h2 className="title">{data.title}</h2>
          </div>
        ) : (
          " "
        )}
      </Link>
    </article>
  )
}

export default ArtTypeCard
